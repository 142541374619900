import { deleteCookie, getCookie } from '@/helpers/cookie'
import axios from 'axios'
import router from '@/router'

const state = {
  status: '',
  token: getCookie('api_token') || '',
  authRequested: false,
  authRequestedType: 'email',
  error: ''
}

const getters = {
  status: state => state.status,
  error: state => state.error,
  isAuthenticated: state => !!state.token,
  authRequested: state => state.authRequested,
  authRequestedType: state => state.authRequestedType
}

const actions = {
  SET_AUTH_STATUS: (context, data) => {
    context.commit('SET_AUTH_STATUS', data)
  },
  AUTH_CONFIRM: (context, data) => {
    context.commit('AUTH_AUTO_CONFIRM', data)
    router.push({ name: 'Home' })
  },
  AUTH_CANCEL: (context) => {
    context.commit('AUTH_CANCEL')
  },
  AUTH_LOGOUT: (context) => {
    deleteCookie('api_token')
    router.push({ name: 'login' })
    context.commit('AUTH_LOGOUT')
    window.location.reload()
  },
  AUTH_ERROR: (context, error) => {
    deleteCookie('api_token')
    context.commit('AUTH_ERROR', error)
  }
}

const mutations = {
  SET_AUTH_STATUS: (state, data) => {
    state.status = data
  },
  AUTH_AUTO_CONFIRM: (state, token) => {
    state.status = 'auth-success'
    state.token = token
    state.authRequested = true
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  },
  AUTH_CANCEL: (state) => {
    state.status = ''
    state.authRequested = false
  },
  AUTH_LOGOUT: (state) => {
    state.status = 'logout'
    state.token = ''
  },
  AUTH_ERROR: (state, error) => {
    state.status = 'error'
    state.error = error
    state.token = ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
