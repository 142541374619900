<template>
  <div>
        <table class="table table-striped small">
          <tr>
            <td rowspan="9" valign="bottom">
              <a :href="srcJpg" target="_blank">
                <img :src="srcJpg"/>
              </a>
            </td>
            <td width="60%" class="text-left">
              <ul>
                <li>
                  Модификация: {{ data.siType }}
                </li>
                <li>
                  Заводской номер: {{ data.serialNumber }}
                </li>
                <li>
                  Дата поверки: {{ data.created_at }}
                </li>
                <li>
                  Действует до: {{ data.nextTest }}
                </li>
                <li>
                  Номер в Госреестре: {{ data.regNumber }}
                </li>
                <li>
                  Методика поверки: {{ data.checkMethod }}
                </li>
                <li>
                  Вода: {{ data.waterType }}
                </li>
                <li>
                  <a :href="`https://fgis.gost.ru/fundmetrology/cm/results?filter_org_title=%D0%9E%D0%9E%D0%9E%20%22%D0%9C%D0%A1-%D0%A0%D0%95%D0%A1%D0%A3%D0%A0%D0%A1%22&amp;filter_mi_number=${data.serialNumber}`" target="_blank">
                    Проверить в ФИФ
                  </a> (в электронном реестре)
                </li>

              </ul>
            </td>
          </tr>
        </table>
  </div>
</template>

<script>
import {ADDRESS} from "../../backserver";

export default {
  name: "InfoMeter",
  props: {
    data: {
      type: Object,
      required: true
    },
    date: {
      type: String,
      required: true
    }
  },
  computed: {
    srcJpg() {
      let str = this.data.meter_photo;
      let date = this.date;
      date = date.slice(0, 7);
      date = date.replace('-', '/');
      str = str.replace('preview/', '');
      str = '/preview/' + date + '/' + str;
      return `${ADDRESS}${str}`;
    }
  }
}
</script>

<style scoped>
  ul {
    list-style: none;
  }
  li {
    padding: 3px;
  }
  img {
    width: 100%;
    /*height: 25vh;*/
  }
</style>
