// import axios from 'axios'

const state = {
  user: {},
  worker: {value: null, label: ''}
}

const getters = {
  user: state => state.user,
  worker: state => state.worker
}

const actions = {
  SET_USER (context, data) {
    context.commit('SET_USER', data)
  },
  SET_WORKER (context, data) {
    context.commit('SET_WORKER', data)
  },
}

const mutations = {
  SET_USER (state, data) {
    state.user = data
  },
  SET_WORKER (state, data) {
    state.worker = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
