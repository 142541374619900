import 'core-js/stable'
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSets as icons } from '@/assets/icons/icons'
import store from '@/store'
import axios from 'axios'
import { getCookie } from '@/helpers/cookie'
// @ts-ignore
import SideBarNavItem from '@/views/base/SideBarNavItem'

// @ts-ignore
import MeterModal from '@/views/modal/MeterModal';
// @ts-ignore
import PdfModal from "@/views/modal/PdfModal";
import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);

Vue.component('meter-modal', MeterModal);
Vue.component('pdf-modal', PdfModal);
Vue.component('SideBarNavItem', SideBarNavItem);

Vue.use(Vuex);


// @ts-ignore
// const icons = icons;

// Vue.use(icons);

/**
 * Авторизационный токен
 */
const token = getCookie('api_token')
if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`
// axios.defaults.baseURL = 'http://127.0.0.1:6383'

const requirePlugin = require.context(
  '@/plugins',
  false,
  /\w+\.(ts|js)$/
)
requirePlugin.keys().forEach((pluginName) => {
  const plugin = requirePlugin(pluginName)
  if (plugin.setup) {
    plugin.setup(Vue)
  }
})


Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)


// @ts-ignore
new Vue({
  el: '#app',
  router,
  store,
  data() {
    return {
      icons: icons
    }
  },
  components: {
    SideBarNavItem
  },
  render: (h) => h(App)
}).$mount('#app')

