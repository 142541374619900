






import { defineComponent } from '@vue/composition-api'
import { USER } from '@/api/user'

export default defineComponent({
  created () {
    USER();
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    }
  },
  watch: {
    isAuthenticated (val) {
      if (val) {
        // this.getNotifications();
        USER()
      } else {
        this.$router.push('/login')
      }
    }
  }
})
