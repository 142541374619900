<template>
  <transition name="pdf-fade">
    <div class="pdfmodal">
      <CRow>
        <CCol class="text-right">
          <CButton @click="onClose"  color="danger" class="mb-2">
            закрыть
          </CButton>
        </CCol>
      </CRow>
      <CRow>
          <CCol sm="6">
            <CRow class="flex-fill">
              <h5>Акт № {{data.number_act}} от {{data.date}} г. </h5>
              <br/>
              <div>
                На основании результатов метрологической поверки ИПУ, в количестве <strong>{{ count_meters }} шт.
                признан<span v-if="count_meters > 1">ы</span> пригодным<span v-if="count_meters > 1">и</span></strong> к дальнейшей эксплуатации.
              </div>

              <table class="table table-striped small">
              <tr>
                <td>Владелец:</td>
                <td>{{ data.miowner }}</td>
              </tr>
              <tr>
                <td>Тип:</td>
                <td>{{ data.type }}</td>
              </tr>
              <tr>
                <td>Передано в Аршин<sup>*</sup>:</td>
                <td>{{ data.exported ? 'да' : 'нет' }}</td>
              </tr>
              <tr>
                <td>ПИН:</td>
                <td>{{ data.pin }}</td>
              </tr>
              <tr>
                <td>Примечание:</td>
                <td>{{ data.address}}</td>
              </tr>
              <tr>
                <td>Поверено счетчиков:</td>
                <td>
                  {{ count_meters }}
                </td>
              </tr>
                <tr>
                  <td colspan="2" class="text-red">
                    <sup>*</sup> Внимание. Данные в Федеральный информационный фонд (ФИФ) могут передаваться до 40 дней.
                  </td>
                </tr>
              </table>
            </CRow>
            <CRow>
              <carousel :per-page="1" :mouse-drag="true" :autoplay="true">
                <slide
                  v-for="(item, index) in data.meters"
                  :key="index">
                  <info-meter :date="data.date" :data="item"></info-meter>
                </slide>
              </carousel>
            </CRow>
          </CCol>
          <CCol sm="6">
              <img :src="srcJpg"/>
          </CCol>
        </CRow>
    </div>
  </transition>
</template>
<script>

import { mapGetters } from 'vuex';
import {ADDRESS} from "../../backserver";

import InfoMeter from "../base/InfoMeter";

// import VuePdfApp from "vue-pdf-app";
// import "vue-pdf-app/dist/icons/main.css";

export default {
  name: "PdfModal",
  components: {
    InfoMeter
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    contract: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      changeStatus: false,
      numPages: 4,
      currentPage: 0,
      pageCount: 0,
      type_show: false
    }
  },
  computed: {
    srcJpg() {
      // console.log('data', this.data);
      if (this.data.date) {
        let str = 'act_' + this.data.name + '.jpg';
        let date = this.data.date;
        date = date.slice(0, 7);
        date = date.replace('-', '/');
        str = str.replace('photos/', '');
        str = '/photo/' + date + '/' + str;
        return `${ADDRESS}${str}`;
      }
      else
        return null;
    },
    count_meters() {
      let result =  this.data.meters ? this.data.meters.length : 0;
      return result;
    }
  },
  methods: {
    onClose() {
      document.body.classList.remove('noscroll');
      // alert('asdasd');
      this.$emit('hide-document');
      this.changeStatus = false;
    },
    changePage(step)
    {
      this.changeStatus = false;
      this.$emit('change-document', step, this.data.current, this.data.documents)
    },
    showMeters() {
      this.type_show = !this.type_show;

    }
  }
}
</script>

<style scoped>

.noscroll{
  overflow: hidden !important;
}
.pdfmodal {
  position: fixed;
  z-index: 10000;
  background-color: #ffffff;
  top: 0;
  right: 0;
  height: 100vh;
  width: 90vw;
  padding: 30px 30px 30px 20px;
  -webkit-box-shadow: -4px 5px 8px 5px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: -4px 5px 8px 5px rgba(34, 60, 80, 0.2);
  box-shadow: -4px 5px 8px 5px rgba(34, 60, 80, 0.2);
}
.pdfmodal .close {
  font-size: 25px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 100;
}
.pdf-fade-enter-active, .pdf-fade-leave-active {
  transition: right 0.5s linear;
  right: 0;
}
.pdf-fade-enter, .pdf-fade-leave-to  {
  right: -100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: #606f7b;
}
img {
  max-height: 100vh;
}
label.form {
  color: white;
  font-family: Monaco, 'Courier New', Courier, monospace;
  font-weight: bold;
  margin-bottom: 2em;
  display: block;
}
input {
  padding: 0.45em;
  font-size: 1em;
}
.error {
  border: 1px solid red;
  background: pink;
  color: red;
  padding: 0.5em 3em;
  display: inline;
}
a.icon {
  cursor: pointer;
  display: block;
  border: 1px #333 solid;
  background: white;
  color: #333;
  font-weight: bold;
  padding: 0.25em;
  width: 1em;
  height: 1em;
  font-size: 1.5em;
}
.box-shadow {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
}
.overflow-hidden {
  overflow: hidden;
}
@media print {
  body {
    background-color: transparent;
  }
  #app {
    margin: 0;
    padding: 0;
  }
}
#meters-slider.carousel-control-prev, #meters-slider.carousel-control-next {
  color: #0b2e13;
}
</style>
