import axios from 'axios'
import store from '@/store'

export const USER = () => {
  return new Promise<any>((resolve) => {
    axios.post('/api/user').then((response) => {
        store.dispatch('SET_USER', response.data.user)
        resolve(response.data)
    })
  })
}
